// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  gap: 8px;
  background: #f4f7fe;
  width: 30px;
  height: 30px;
  border-radius: 9px;
  padding: 4px;
  padding-top: -15px;
  font-size: 32px;
  padding-bottom: 23px;
  color: #422afb;
}`, "",{"version":3,"sources":["webpack://./src/components/menu/mainMenu.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,oBAAA;EACA,cAAA;AACF","sourcesContent":[".dropdown {\n  gap: 8px;\n  background: #f4f7fe;\n  width: 30px;\n  height: 30px;\n  border-radius: 9px;\n  padding: 4px;\n  padding-top: -15px;\n  font-size: 32px;\n  padding-bottom: 23px;\n  color: #422afb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
