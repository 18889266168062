// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-table-container {
  align-items: center;
  display: jus;
  display: flex;
  justify-content: space-between;
}

.data-table-wrapper {
  background: transparent;
  padding: 10px 0 50px 0;
  height: 90vh;
}
.data-table-wrapper .ant-space-item {
  width: 100%;
}
.data-table-wrapper .ant-card-bordered {
  border-radius: 23px;
  height: 100%;
}
.data-table-wrapper .first-row {
  width: 100%;
}
.data-table-wrapper .first-row .product-data-revenue {
  overflow-x: scroll;
}
.data-table-wrapper .sec-row {
  width: 100%;
  margin-top: 14px;
  margin-bottom: 16px;
}
.data-table-wrapper .sec-row .column-data {
  overflow-x: scroll;
}
.data-table-wrapper .sec-row .priority-data-revenue {
  overflow-x: scroll;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: white;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  color: #a0aec0;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-weight: 700;
  color: #3d4672;
}

.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: inherit;
}

@media screen and (max-width: 600px) {
  .data-revenue {
    overflow-x: scroll;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .data-revenue {
    overflow-x: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./src/dashboard/audit/audit.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,uBAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,mBAAA;EACA,YAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAKE;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;AAHJ;AAKI;EACE,kBAAA;AAHN;AAMI;EACE,kBAAA;AAJN;;AASA;EACE,iBAAA;AANF;;AASA;EACE,cAAA;AANF;;AASA;EACE,gBAAA;EACA,cAAA;AANF;;AASA;EACE,iBAAA;AANF;;AAWE;EADF;IAEI,kBAAA;EAPF;AACF;AASE;EALF;IAMI,kBAAA;EANF;AACF","sourcesContent":[".dev-table-container {\n  align-items: center;\n  display: jus;\n  display: flex;\n  justify-content: space-between;\n}\n\n.data-table-wrapper {\n  background: transparent;\n  padding: 10px 0 50px 0;\n  height: 90vh;\n\n  .ant-space-item {\n    width: 100%;\n  }\n\n  .ant-card-bordered {\n    border-radius: 23px;\n    height: 100%;\n  }\n\n  .first-row {\n    width: 100%;\n\n    .product-data-revenue {\n      overflow-x: scroll;\n    }\n  }\n\n  .sec-row {\n    width: 100%;\n    margin-top: 14px;\n    margin-bottom: 16px;\n\n    .column-data {\n      overflow-x: scroll;\n    }\n\n    .priority-data-revenue {\n      overflow-x: scroll;\n    }\n  }\n}\n\n.ant-table-wrapper .ant-table-thead>tr>th {\n  background: white;\n}\n\n.ant-table-wrapper .ant-table-thead>tr>th {\n  color: #a0aec0;\n}\n\n.ant-table-wrapper .ant-table-tbody>tr>td {\n  font-weight: 700;\n  color: #3d4672;\n}\n\n.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {\n  position: inherit;\n}\n\n//Revenue\n.data-revenue {\n  @media screen and (max-width: 600px) {\n    overflow-x: scroll;\n  }\n\n  @media screen and (min-width: 600px) and (max-width: 1200px) {\n    overflow-x: scroll;\n  }\n}\n\n//Revenue end"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
