import React from "react";

const EnvButton = ({ env }) => {
  const handleClick = () => {
    localStorage.setItem("env", env);
  };

  return <button onClick={handleClick}>{env}</button>;
};

export default EnvButton;
