import Container from "./containers";
import React from "react";
import { AuthProvider } from "@descope/react-sdk";

const AppRoot = () => {
  const conf = {
    old: {
      projectId: "P2anjDqtRizIB4GU4GxAcTPGPYfg",
      contentBaseUrl: "https://static.descope.com/pages",
    },
    new: {
      projectId: "P2T0tr8Wxyey6xnzw5lrpglj9ZV9",
      contentBaseUrl: "https://static.descope.org/pages",
    },
  };

  const env = localStorage.getItem("env") ?? "old";
  const { projectId, contentBaseUrl } = conf[env];

  if (projectId !== localStorage.getItem("projectId")) {
    localStorage.removeItem("DSR");
    localStorage.removeItem("DS");
    localStorage.setItem("projectId", projectId);
  }
  localStorage.setItem("base.content.url", contentBaseUrl);

  return (
    <AuthProvider
      projectId={projectId}
      baseUrl={"https://auth.tom-sso.descope.team"}
      contentBaseUrl={contentBaseUrl}
    >
      <App />
    </AuthProvider>
  );
};

const App = () => {
  return (
    <div className="App">
      <Container />
    </div>
  );
};

export default AppRoot;
