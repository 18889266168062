// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container .ant-menu::before {
  display: block;
}
.sidebar-container .img-dolrr {
  width: 100%;
  height: 26px;
  line-height: 1em;
  margin-top: 58px;
  margin-bottom: 32px;
}
@media screen and (max-width: 600px) {
  .sidebar-container .img-dolrr {
    margin-top: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .sidebar-container .img-dolrr {
    margin-top: 20px;
  }
}
.sidebar-container .divider {
  padding-bottom: 24px;
}
.sidebar-container .ant-menu-light .ant-menu-item-selected {
  background-color: white;
  color: #2d3748;
  font-size: 16px;
  font-weight: 700;
}
.sidebar-container .ant-divider-horizontal {
  margin: 0;
}
.sidebar-container .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: white;
}
.sidebar-container .ant-menu .ant-menu-item .ant-menu-item-icon {
  color: #7151ff;
}
.sidebar-container :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-light.ant-menu-root.ant-menu-inline,
.sidebar-container :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-light.ant-menu-root.ant-menu-vertical {
  padding: 0 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAGI;EAPF;IAQI,gBAAA;EAAJ;AACF;AACI;EAVF;IAWI,gBAAA;EAEJ;AACF;AAAE;EACE,oBAAA;AAEJ;AAAE;EACE,uBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AACE;EACE,SAAA;AACJ;AACE;EAEE,uBAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;;EAME,eAAA;AANJ","sourcesContent":[".sidebar-container {\n  .ant-menu::before {\n    display: block;\n  }\n\n  .img-dolrr {\n    width: 100%;\n    height: 26px;\n    line-height: 1em;\n    margin-top: 58px;\n    margin-bottom: 32px;\n\n    @media screen and (max-width: 600px) {\n      margin-top: 20px;\n    }\n    @media screen and (min-width: 600px) and (max-width: 1200px) {\n      margin-top: 20px;\n    }\n  }\n  .divider {\n    padding-bottom: 24px;\n  }\n  .ant-menu-light .ant-menu-item-selected {\n    background-color: white;\n    color: #2d3748;\n    font-size: 16px;\n    font-weight: 700;\n  }\n\n  .ant-divider-horizontal {\n    margin: 0;\n  }\n  .ant-menu-light:not(.ant-menu-horizontal)\n    .ant-menu-item:not(.ant-menu-item-selected):hover {\n    background-color: white;\n  }\n\n  .ant-menu .ant-menu-item .ant-menu-item-icon {\n    color: #7151ff;\n  }\n\n  :where(\n      .css-dev-only-do-not-override-1km3mtt\n    ).ant-menu-light.ant-menu-root.ant-menu-inline,\n  :where(\n      .css-dev-only-do-not-override-1km3mtt\n    ).ant-menu-light.ant-menu-root.ant-menu-vertical {\n    padding: 0 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
