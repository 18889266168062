// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1200px) {
  .siderbar-style {
    display: none;
  }
}

.header-style {
  text-align: center;
  color: black;
  margin-left: 300px;
}
@media screen and (max-width: 600px) {
  .header-style {
    margin-left: 0px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .header-style {
    margin-left: 0px;
  }
}

.content-style {
  margin-top: 41px;
  padding: 0 19px;
  margin-left: 300px;
}
@media screen and (max-width: 600px) {
  .content-style {
    padding-top: 98px;
    margin-left: 0;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .content-style {
    margin-left: 0px;
  }
}

.ant-layout .ant-layout-header {
  padding-inline: 19px;
  background: #f2f6fd;
}
@media screen and (max-width: 600px) {
  .ant-layout .ant-layout-header {
    padding-inline: 11px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .ant-layout .ant-layout-header {
    padding-inline: 34px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/projectLayout.scss"],"names":[],"mappings":"AACE;EADF;IAEI,aAAA;EACF;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;AAEF;AAAE;EALF;IAMI,gBAAA;EAGF;AACF;AAFE;EARF;IASI,gBAAA;EAKF;AACF;;AAHA;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AAMF;AAJE;EALF;IAMI,iBAAA;IACA,cAAA;EAOF;AACF;AANE;EATF;IAUI,gBAAA;EASF;AACF;;AAPA;EACE,oBAAA;EACA,mBAAA;AAUF;AARE;EAJF;IAKI,oBAAA;EAWF;AACF;AAVE;EAPF;IAQI,oBAAA;EAaF;AACF","sourcesContent":[".siderbar-style {\n  @media screen and (max-width: 1200px) {\n    display: none;\n  }\n}\n.header-style {\n  text-align: center;\n  color: black;\n  margin-left: 300px;\n\n  @media screen and (max-width: 600px) {\n    margin-left: 0px;\n  }\n  @media screen and (min-width: 600px) and (max-width: 1200px) {\n    margin-left: 0px;\n  }\n}\n.content-style {\n  margin-top: 41px;\n  padding: 0 19px;\n  margin-left: 300px;\n\n  @media screen and (max-width: 600px) {\n    padding-top: 98px;\n    margin-left: 0;\n  }\n  @media screen and (min-width: 600px) and (max-width: 1200px) {\n    margin-left: 0px;\n  }\n}\n.ant-layout .ant-layout-header {\n  padding-inline: 19px;\n  background: #f2f6fd;\n\n  @media screen and (max-width: 600px) {\n    padding-inline: 11px;\n  }\n  @media screen and (min-width: 600px) and (max-width: 1200px) {\n    padding-inline: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
