// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-row {
  height: 100%;
}
.main-row .left-container {
  overflow-y: auto;
  height: 100%;
}
.main-row .left-container .sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 82px;
}
.main-row .right-container {
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .main-row .right-container {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .main-row .right-container {
    display: none;
  }
}
.main-row .right-container .img-banner {
  border-bottom-left-radius: 200px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/auth/signIn/sign.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,gBAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AAGN;AACE;EACE,YAAA;AACJ;AACI;EAHF;IAII,aAAA;EAEJ;AACF;AADI;EANF;IAOI,aAAA;EAIJ;AACF;AAFI;EACE,gCAAA;EACA,YAAA;AAIN","sourcesContent":[".main-row {\n  height: 100%;\n  .left-container {\n    overflow-y: auto;\n    height: 100%;\n    .sign-in-container {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      padding-top: 82px;\n      // overflow-y: auto;\n    }\n  }\n  .right-container {\n    height: 100%;\n  \n    @media only screen and (max-width: 600px) {\n      display: none;\n    }\n    @media only screen and (min-width: 600px) and (max-width: 1000px) {\n      display: none;\n    }\n  \n    .img-banner {\n      border-bottom-left-radius: 200px;\n      height: 100%;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
